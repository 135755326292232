import React, { useState } from "react";
import "./NewPage.css";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Modal } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cardGiftcardIcon: {
    width: "25vw",
    height: "auto",
    marginTop: "150px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  playerWrap: {
    display: "flex",
    justifyContent: "center", // center horizontally
    alignItems: "center", // center vertically
    height: "100%",
    width: "100%",
    position: "relative",
  },
  iframe: {
    width: "80%",
    height: "80%", // adjust this as needed
    border: "0",
  },
  closeButton: {
    position: "absolute", // position it absolute...
    top: "10px", // ...at the top...
    right: "10px", // ...and the right
    padding: "10px", // This ensures the click area is large
    backgroundColor: "white", // This makes the button visible against any background
    color: "black", // This is the color of the 'X' icon
  },
}));

function NewPage() {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClickClose = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <Modal open={isOpen} onClose={handleClickClose} className={classes.modal}>
        <div className={classes.playerWrap}>
          <iframe
            title={"sign"}
            className={classes.iframe}
            src="https://www.youtube.com/embed/bgXqIRyJXHc"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <IconButton
            className={classes.closeButton}
            onClick={handleClickClose}
          >
            <CloseIcon />{" "}
            {/* This icon comes from the @material-ui/icons package */}
          </IconButton>
        </div>
      </Modal>
      <div className="py-5 text-center text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto">
              <iframe
                title={"YouandMeMaldives"}
                src="https://geckodigital.co/vt/YouandMeMaldives/"
                width="100%"
                height="600px"
                frameBorder="0"
                className="your-class"
              />
              <div className="yourContainerClass">
                <h1 className="mb-3">Happy Early Anniversary</h1>
                <p className="lead mb-0">
                  We have 10 Days in Maldives at the You and Me Adult only
                  <br />
                  Cocoon Collection booked leaving on the 14th of Feb.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewPage;
